<template lang="pug">
.auth
  LHeader
  slot

</template>

<script>
import LHeader from '@/components/global/LHeader'

export default {
  name: 'auth',
  components: {
    LHeader
  }
}
</script>

<style lang="scss">
.auth {
  & .lheader {
    position: relative;
    z-index: 10100; // 100 more after popup
  }
}
</style>