import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Cookies from 'js-cookie'

import userStore from '@/store/user'
// stub dublicate route error

import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
};
// end stub
Vue.use(VueRouter)

const routes = [
  {
    // route
    path: '/app',
    name: 'FeedAdvertising',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Home.vue'),
    meta: {
      layout: 'home'
      
    },
  },

  {
    // route
    path: '/app/followings',
    name: 'Feed',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Home.vue'),
    meta: {
      layout: 'home',
      tab: 'followings'
    },
  },
  {
    // route
    path: '/app/search',
    name: 'SerachView',
    component: () => import(/* webpackChunkName: "profile" */ '../views/SearchView.vue'),
    meta: {
      layout: 'home'
    },
  },

  {
    // route
    path: '/app/category/:slug',
    name: 'CategoryView',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Category.vue'),
    meta: {
      layout: 'home'
    },
  },

  {
    // route
    path: '/app/all',
    name: 'FeedAll',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Home.vue'),
    meta: {
      layout: 'home',
      tab: 'all'
    },
  },

  {
    // route
    path: '/app/user/:uuid',
    name: 'User',
    component: () => import(/* webpackChunkName: "profile" */ '../views/User.vue'),
    meta: {
      layout: 'home',
    },
  },

  {
    // route
    path: '/app/partner/:uuid',
    name: 'Partner',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Partner.vue'),
    meta: {
      layout: 'home',
    },
  },

  {
    // route
    path: '/app/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileCreatePost.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/user/:uuid',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileUserView.vue'),
    meta: {
      layout: 'profile',
    },
  },
  {
    // route
    path: '/app/post/:uuid',
    name: 'PostView',
    component: () => import(/* webpackChunkName: "profile" */ '../views/PostView.vue'),
    meta: {
      layout: 'home',
    },
  },
  {
    // route
    path: '/app/profile/post/:uuid/edit',
    name: 'PostEdit',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfilePost.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/partner/:uuid',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfilePartnerView.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/follows',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileFollows.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/friends',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileFriends.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/partners',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfilePartners.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/photoandvideo',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfilePhotoAndVideo.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/myposts',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileMyPosts.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/myadvert',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileMyAdvert.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/balance',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileBalance.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/statistic',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileStatistic.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/refer',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileRefer.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/followers',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileFollowers.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/profile/settings',
    name: 'ProfileSettings',
    component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfileSettings.vue'),
    meta: {
      layout: 'profile',
    },
  },

  {
    // route
    path: '/app/messages/:uuid',
    name: 'Message',
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
    meta: {
      layout: 'messages',
    },
  },

  {
    // route
    path: '/app/messages',
    name: 'Messages',
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
    meta: {
      layout: 'messages',
    },
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const token = Cookies.get('token')
  console.log('TOKEN', !!token)
  if (!!token) next()
  else window.location.href = '/'
  
})


export default router
