<template lang="pug">
.home
  .home__wrapper
    .home__sidebar

      LCategories

      LFilters(
        @change="changeFilters"
        :filters="GET_FILTERS"
      )

    .home__feed
      LTabs(@click="changeUrl")
        LTab(:title="$t('Реклама')" :selected="selectedTab === 'FeedAdvertising' " )
          LCard(
            v-for="item, index in advert"
            :data="item"
            :key="index"
            @follow="toggleFollow"
          )
          <infinite-loading @infinite="getAdvert" :identifier="advertId">
            template(slot="spinner")
              LCardShadow

            template(slot="no-more")
              p

            template(slot="no-results")
              p

          </infinite-loading>

        template(v-if="userRole === 'user'")
          LTab(:title="$t('Подписки')" :selected="selectedTab === 'Feed'" stub="followings")
            LCard(
              v-for="item, index in followings"
              :data="item"
              :key="index"
              @follow="toggleFollow"
            )
            <infinite-loading @infinite="getFollowings" :identifier="followingsId" stub="followings">
              template(slot="spinner")
                LCardShadow

              template(slot="no-more")
                p

              template(slot="no-results")
                p

            </infinite-loading>

          LTab(:title="$t('Общее')" :selected="selectedTab === 'FeedAll' " stub="all")
            LCard(
              v-for="item, index in all"
              :data="item"
              :key="index"
              @follow="toggleFollow"
            )
            <infinite-loading @infinite="getAll" :identifier="allId">
              template(slot="spinner")
                LCardShadow

              template(slot="no-more")
                p

              template(slot="no-results")
                p

            </infinite-loading>

    .home__banners

</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    LButton: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LButton'),
    LIcon: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LIcon'),
    LAccordion: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAccordion'),
    LCard: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCard'),
    LCardShadow: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LCardShadow'),
    LTabs: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTabs'),
    LTab: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LTab'),
    LAutocomplite: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAutocomplite'),
    LFilters: () => import(/* webpackChunkName: "composite" */ '@/components/composite/LFilters'),
    LCategories: () => import(/* webpackChunkName: "composite" */ '@/components/composite/LCategories'),
  },
  created () {
    this.selectedTab = this.$route.name
  },
  data () {
    return {
      followings: [],
      advert: [],
      all: [],
      selectedTab: 'FeedAdvertising',

      followingsPagination: {},
      followingCursor: null,

      advertPagination: {},
      advertCursor: null,

      allPagination: {},
      allCursor: null,

      followingsId: 1,
      advertId: 1,
      allId: 1

    }
  },
  computed: {
    ...mapGetters('posts', ['GET_FILTERS']),
    ...mapGetters('user', ['SETTINGS']),
    filters () {
      let filters = {}
      if (this.GET_FILTERS !== null && Object.keys(this.GET_FILTERS).length > 0) {
        let countries = []
        if (this.GET_FILTERS.countries !== undefined) {
          this.GET_FILTERS.countries.forEach(country => {
            countries.push(country.slug)
          })
          filters['countries'] = countries
        }
        if (this.GET_FILTERS.cities !== undefined) {
          let cities = []
          this.GET_FILTERS.cities.forEach(city => {
            cities.push(city.slug)
          })
          filters['cities'] = cities
        }
      } else {
        filters = null
      }
      return filters
    },
    userRole () {
      return this.SETTINGS?.type || false
    }
  },
  methods: {
    changeUrl (url) {
      console.log(url)
      this.$router.push('/app' + `/${url}`)
    },
    ...mapActions('posts', ['feedFollowing', 'feedAdvert', 'feedAll', 'setFilters']),
    toggleFollow (data) {
      this.followings.forEach((item, index) => {
        if (item.author.uuid === data.uuid) {
          let newItem = item
          newItem.author.is_follow = data.status
          this.followings.splice(index, 1, newItem)
        }
      })
      this.advert.forEach((item, index) => {
        if (item.author.uuid === data.uuid) {
          let newItem = item
          newItem.author.is_follow = data.status
          this.advert.splice(index, 1, newItem)
        }
      })
      this.all.forEach((item, index) => {
        if (item.author.uuid === data.uuid) {
          let newItem = item
          newItem.author.is_follow = data.status
          this.all.splice(index, 1, newItem)
        }
      })
    },
    getFollowings (loader) {
      this.feedFollowing({ cursor: this.followingsCursor, filters: this.filters })
      .then((response) => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.followings.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.followingsCursor = response.data.data.meta.cursor
        }
      })
    },
    getAdvert (loader) {
      this.feedAdvert({ cursor: this.advertCursor, filters: this.filters })
      .then(( response ) => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.advert.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.advertCursor = response.data.data.meta.cursor
        }
      })
    },
    getAll (loader) {
      this.feedAll({ cursor: this.allCursor, filters: this.filters })
      .then((response) => {
        if (response.status !== 200) {
          loader.complete()
          return
        }
        this.all.push(...response.data.data.data)
        if (response.data.data.meta.cursor === null) {
          loader.complete()
        } else {
          loader.loaded()
          this.allCursor = response.data.data.meta.cursor
        }
      })
    },
    reloadAll () {
      this.followingsId += 1
      this.followingsCursor = null
      this.followings = []

      this.advertId += 1
      this.advertCursor = null
      this.advert = []

      this.allId += 1
      this.allCursor = null
      this.all = []
      console.log('RELOAD')
    },
    changeFilters(filters) {
      console.log('CHANGE FILTERS')
      this.setFilters({
        filters: filters,
        type: 'feed'
      })
      this.reloadAll()
    }
  }
}
</script>

<style lang="scss">
.home {
  background: #FAF9F9;
  min-height: 100vh;
  &__nav {
    display: flex;
    width: 50%;
    justify-content: space-between;
    height: 40px;
    margin-top: 60px;
    &-item {
      cursor: pointer;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      &_active {
        font-weight: 700;
        position: relative;
        &:after {
          position: absolute;
          bottom: 0;
          top: 22px;
          left: 0;
          right: 0;
          height: 3px;
          width: 100%;
          content: '';
          background: #C31A14;
          opacity: .6;
        }
      }
    }
  }
  &__wrapper {
    width: 100%;
    display: flex;
    max-width: 1170px;
    margin: 0 auto;
  }
  &__sidebar {
    width: 300px;
    padding-top: 100px;
  }
  &__feed {
    width: calc(100% - 440px);
    box-sizing: border-box;
    padding: 0 30px;
    padding-top: 40px;
    & .ltabs__header {
      width: 50%;
      margin-bottom: 10px;
    }
  }
}


.accordion {
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 60px 30px;
  margin-bottom: 30px;
  &__title {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 30px;
  }
  &__sub-item {
    font-size: 12px;
    margin-bottom: 15px;
  }
  &__header {
    cursor: pointer;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

</style>