<template lang="pug">
.layout-profile
  LHeader
  .layout-profile__container
    aside.layout-profile__aside
      template(v-if="!preloader")
        .layout-profile__aside-user
          //- TODO PRELOADER
          LAvatar(:url="SETTINGS.photo.preview")
          .mb-4.mt-2 {{ SETTINGS.name }}

        .layout-profile__aside-item(
          @click="$router.push('/app/profile')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('upload')} ]"
        ) Загрузить материал
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/follows')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('follows')} ]"
        ) Подписки
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/followers')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('followers')} ]"
        ) Подписчики
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/friends')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('friends')} ]"
        ) Друзья
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/partners')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('partners')} ]"
        ) Партнёры
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/myposts')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('myposts')} ]"
        ) Мои посты
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/photoandvideo')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('photoandvideo')} ]"
        ) Фото/Видео
        .layout-profile__aside-item(
        v-if="SETTINGS.type === 'user'"
          @click="$router.push('/app/profile/myadvert')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('myadvert')} ]"
        ) Моя реклама
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/balance')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('balance')} ]"
        ) Баланс
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/statistic')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('statistic')} ]"
        ) Статистика
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/refer')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('refer')} ]"
        ) Приведи друга
        .layout-profile__aside-item(
          @click="$router.push('/app/profile/settings')"
          :class="[ {'layout-profile__aside-item_active': containsRoute('settings')} ]"
        ) Настройки аккаунта
        .layout-profile__aside-item(
          @click="logoutHandler"
        ) Выйти из аккаунта

    .layout-profile__content
      slot

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'LayoutProfile',
  components: {
    LHeader: () => import(/* webpackChunkName: "ui" */ '@/components/global/LHeader'),
    LAvatar: () => import(/* webpackChunkName: "ui" */ '@/components/ui/LAvatar'),
  },
  created () {
    this.settings()
    .then(r => {
      console.log('READY')
      this.preloader = false
    })
  },
  data () {
    return {
      preloader: true
    }
  },
  computed: {
    ...mapGetters('user', ['SETTINGS'])
  },
  methods: {
    ...mapActions('user', ['settings', 'logout']),
    containsRoute (part) {
      let path = this.$route.path
      if (part === 'upload' && this.$route.path === '/app/profile') return true
      return !!(path.indexOf(part) + 1)
    },
    logoutHandler () {
      this.logout()
      window.location.href = '/'
    }
  }
}
</script>

<style lang="scss">
body {
  overflow-y: scroll
}
.layout-profile {
  max-width: 1170px;
  margin: 0 auto;
  &__container {
    display: flex;
  }
  &__aside {
    @media (min-width: 768px) {
      width: 260px;
    }
    &-item {
      background: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 15px;
      &:hover {
        background: #F4F4F4;
        color: #000;
      }
      &:active {
        box-shadow: none;
        background: #C4C4C4;
        color: #fff;
      }
      &_active {
        background: #C4C4C4;
        color: #fff;
      }
    }
    &-user {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 40px;
    }
  }
  &__content {
    width: calc(100% - 260px);
  }
}
</style>