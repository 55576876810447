<template lang="pug">
.layout-messages
  LHeader
  .layout-messages__view
    slot

</template>

<script>
import LHeader from '@/components/global/LHeader'

export default {
  name: 'LayoutMessages',
  components: {
    LHeader
  }
}
</script>

<style lang="scss">
.layout-messages {
}
</style>