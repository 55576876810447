<template lang="pug">
#app
  //- <div id="nav">
  //-   <router-link to="/">Home</router-link> |
  //-   <router-link to="/about">About</router-link>
  //- </div>
  component(:is="layout")
    routerView

  portalTarget(name="popups")

</template>

<script>
// LAYOUTS
import auth from "@/layouts/auth"
import home from "@/layouts/home"
import profile from "@/layouts/profile"
import messages from "@/layouts/messages"

import { mapActions } from 'vuex'

export default {
  name: "App",
  components: {
    auth,
    home,
    profile,
    messages
  },
  created () {
    // hide landing
    this.loadAuth()
  },
  computed: {
    layout() {
      console.log('LAYOUT', this.$route.meta.layout)
      return this.$route.meta.layout
    }
  },
  methods: {
    ...mapActions('user', ['loadAuth'])
  }
}
</script>


<style lang="scss">
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #FAF9F9;
  min-height: 100vh;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
