import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueAxios from 'vue-axios'
import PortalVue from 'portal-vue'
import axios from './axios.js'

import InfiniteLoading from 'vue-infinite-loading';
import VueCroppie from 'vue-croppie';
import focus from './directives/focus.js'


Vue.directive('focus', focus)
Vue.use(VueCroppie)
Vue.use(InfiniteLoading, { /* options */ });

Vue.use(PortalVue)
Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


