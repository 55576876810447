<template lang="pug">
.layout-default
  LHeader
  .layout-default__banner
  .layout-default__container
    .layout-default__sidebar
    .layout-default__feed
      slot

    .layout-default__banners

</template>

<script>
import LHeader from '@/components/global/LHeader'

export default {
  name: 'LayoutHome',
  components: {
    LHeader
  }
}
</script>

<style lang="scss">
.layout-default {
  &__banner {
    width: 100%;
    height: 120px;
    background: #c4c4c4;
  }
}
</style>